import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect, useState, Suspense, lazy} from 'react';
import {Helmet} from 'react-helmet';
import {Redirect} from 'react-router-dom';

import Image from '../Image/Image';
import markdownOptions from './markdownOptions';
import StoreContext from '../../storeContext';
import Video from '../Video/Video';
import i18n, {getLanguageDependendContent} from '../../helpers/i18n';

const Branchmatrix = lazy(() => import('../Branchmatrix/Branchmatrix'));
const Footer = lazy(() => import('../Footer/Footer'));
const Header = lazy(() => import('../Header/Header'));
const NewsSection = lazy(() => import('../News/NewsSection'));
const QuickContact = lazy(() => import('../QuickContact/QuickContact'));
const Translation = lazy(() => import('../Translation/Translation'));

const PageStandard = ({page}) => {
  const [redirect, setRedirect] = useState(null);
  const {state} = useContext(StoreContext);

  // class="" ersetzen durch className=""
  page.description = page.description ? page.description.replace(/class="/i, 'className="') : page.description
  page.description_en = page.description_en ? page.description_en.replace(/class="/i, 'className="') : page.description_en

  const getTitle = () => page && page.title ? <title>{getLanguageDependendContent(page, 'title')}</title> : <title>Brainority Software GmbH</title>;
  const getMetaDescription = () => page && page.seo_description ? <meta name="description" content={getLanguageDependendContent(page, 'seo_description')} /> : null;
  const getMetaKeywords = () => page && page.seo_keywords ? <meta name="keywords" content={getLanguageDependendContent(page, 'seo_keywords')} /> : null;
  const getH1Title = () => page && page.title ? <h1 className="fw-bold">{getLanguageDependendContent(page, 'title')}</h1> : null;
  const getSubHeading = () => page && page.subheading ? <p className="display-3">{getLanguageDependendContent(page, 'subheading')}</p> : null;
  const getTeaser = () => page && page.teaser ? <p className="mt-4 p">{getLanguageDependendContent(page, 'teaser')}</p> : null;
  const getPageContent = () => page && page.description ? <Markdown options={markdownOptions(state.routes, state.uploads)}>{getLanguageDependendContent(page, 'description')}</Markdown> : null;

  i18n({language: state.language});

  useEffect(() => {
    setTeaserImage(window.innerWidth);

    window.addEventListener("resize", () => {
      setTeaserImage(window.innerWidth);
    });

    window._paq.push(['setCustomUrl', '/' + window.location.hash.substr(1)]);
    window._paq.push(['setDocumentTitle', page.title]);
    window._paq.push(['trackPageView']);

    return () => setRedirect(null);
  });

  const setTeaserImage = (windowInnerWith) => {
    const teaserImageFound = state.uploads.find(upload => page.teaser_image === upload.url);
    const teaserImage = teaserImageFound ? `${teaserImageFound.url}` : state.startpage.teaser_image;
    const domElement = document.querySelector('header#teaser') ? document.querySelector('header#teaser') : undefined;

    if (domElement && windowInnerWith < 992) {
      domElement.style.background = '';
    }

    else if (domElement && windowInnerWith >= 992 && windowInnerWith < 1200) {
      domElement.style.background = `url('/uploads/teaser-schraege.png') 340px 50% / contain no-repeat, url(${teaserImage}) -45px center no-repeat #dcdcdc`;
    }

    else if (domElement && windowInnerWith >= 1200) {
      domElement.style.background = `url('/uploads/teaser-schraege.png') 385px 50% / contain no-repeat, url(${teaserImage}) left center no-repeat #dcdcdc`;
    }
  }
  
  const getContent = () => !page.startpage ? <React.Fragment>
    <div className="container-fluid mt-lg-3 py-lg-4">
      <div className="container">
        <div className="row">
          <div className="col">
            {getPageContent()}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment> : null;

  const getFrontPageContent = () => page.startpage ? <>
    <div className="container-fluid my-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7 pe-4">
            {getPageContent()}
          </div>
          <div className="col-12 col-lg-5 border-lg-left ps-lg-4">
            <h2 className="text-primary mt-4 mb-2"><Translation markdown={true}>CAN_YOU_TELL_THE_DIFFERENCE</Translation></h2>
            <Video 
              className="mt-3 cursor-pointer"
              preview="/uploads/video-preview.jpg"
              time="2:13"
              src={state.translations.find(translation => translation.key === 'PRODUCT_VIDEO_SRC')[`value_${state.language}`]} 
              alt={state.translations.find(translation => translation.key === 'CAN_YOU_TELL_THE_DIFFERENCE')[`value_${state.language}`]} 
            />
          </div>
        </div>
      </div>
    </div>

    <NewsSection exclude="Wissenswertes" />

    <Branchmatrix category="Branchen" />
    <div className="container-fluid mt-3 py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-3 col-lg-3 text-center"><Image src="/uploads/gs1-solutionpartner-original.png" alt="GS1 Solution Partner" fluid height="128" /></div>
          <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0 text-center"><Image src="/uploads/software-made-in-germany-smig.png" alt="Software made in Germany (SmiG)" fluid width="200" height="128" /></div>
          <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0 text-center"><Image src="/uploads/software-hosted-in-germany.png" alt="Software hosted in Germany" fluid width="131" height="128" /></div>
          <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0 text-center"><Image src="/uploads/dqs-informationstechnologie-iso-27001.png" alt="DQS Informationstechnologie ISO 27001" fluid width="148" height="128" /></div>
        </div>
      </div>
    </div> 
    </> : null;

  return redirect ? 
    <Redirect to={redirect} /> : <>
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet htmlAttributes={{'lang': state.language}}>
        {getTitle()}
        {getMetaDescription()}
        {getMetaKeywords()}
      </Helmet>
      <QuickContact />
      <Header page={page} />
      <header className="container-fluid" id="teaser">
        <div className="container">
          <div className="row justify-content-end align-items-center">
            <div className="col-12 col-md-12 col-lg-7 col-xl-7 col-xxl-8 col-xxxl-9 col-xxxxl-12 py-3">
              {getSubHeading()}
              {getH1Title()}
              {getTeaser()}
            </div>
          </div>
        </div>
      </header>
      {getFrontPageContent()}
      {getContent()}
      <Footer />
    </Suspense>
  </>

}

export default PageStandard;