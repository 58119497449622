import React, {useContext, useEffect, useState, Suspense} from 'react';
import Markdown from 'markdown-to-jsx';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import StoreContext from '../../storeContext';
import markdownOptions from './markdownOptions';

const PageCorporateIdentity = () => {
  const {state} = useContext(StoreContext);
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const getMarkdownFile = async () => {
      const markdownRequest = await fetch('/CorporateIdentity.md');
      const markdownResponse = await markdownRequest.text();
      setMarkdownContent(markdownResponse);
    }

    getMarkdownFile();
  });

  return <>
    <Suspense fallback={<div>Loading...</div>}>
      <Header />
      <div className="container py-4 text-primary">
        <div className="row">
          <div className="col-12">
            <Markdown># Corporate Identity (CI)</Markdown>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-1 border-linie"></div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col">
            <Markdown options={markdownOptions(state.pages, state.uploads)}>{markdownContent}</Markdown>
          </div>
        </div>
      </div>
      <Footer />
    </Suspense>
  </>

}

export default PageCorporateIdentity;