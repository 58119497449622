import React, {useContext} from 'react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import {getLanguageDependendPermalink} from '../../helpers/i18n';
import StoreContext from '../../storeContext';

const PageSitemap = ({page}) => {
  const {state} = useContext(StoreContext);

  const getLinkList = () => state.pages.map(page => 
    state.languages.map(language => 
      <li key={page.id + language}>
        <a href={getLanguageDependendPermalink(page, language)}>{getLanguageDependendPermalink(page, language)}</a>
      </li>
    )
  );

  return <>
    <Header page={{permalink: '/sitemap'}} />
    <div className="container mt-3">
      <div className="row">
        <div className="col">
          <ul className="mt-3">
            {getLinkList()}
          </ul>
        </div>
      </div>
    </div>
    <Footer />    
  </>

}

export default PageSitemap;