import React, {Component} from 'react';

class Milestone extends Component {

  getImage = () => {
    const hasClasses = "col-12 col-xl-5 order-first text-center bg-primary text-white p-5 d-flex align-items-center justify-content-center".split(' ');

    this.props.position === 'image-text' ? hasClasses.push('order-xl-first') : hasClasses.push('order-xl-last');

    return <div className={hasClasses.join(' ')}>
      <h1>{this.props.year}</h1>
    </div>
  }

  getText = () => {
    const hasClasses = "col-12 col-xl-7 order-last px-5".split(' ');

    this.props.position === 'image-text' ? hasClasses.push('order-xl-last') : hasClasses.push('order-xl-first');

    return <div className={hasClasses.join(' ')}>
      {this.props.children}
    </div>
  }

  render = () => 
    <>
      <div className="container mt-5">
        <div className="row mb-5 justify-content-center">
          {this.getImage()}
          {this.getText()}
        </div>
        <div className="row mb-5 justify-content-center border d-none d-xl-block"></div>
      </div>

    </>

}

export default Milestone;