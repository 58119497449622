let language = '';

const getLanguageDependendContent = (haystack, needle, passedLanguage) => {
  const currentLanguage = passedLanguage ? passedLanguage : language;
  
  if (currentLanguage === 'de') {
    return haystack[`${needle}`]
  } else if (haystack[`${needle}_${currentLanguage}`]) {
    return haystack[`${needle}_${currentLanguage}`];
  } else {
    return '';
  }
}

const getLanguageDependendPermalink = (page, passedLanguage) => {
  const currentLanguage = passedLanguage ? passedLanguage : language;

  if (currentLanguage === 'de') {
    return page.permalink
  } else if (page[`permalink_${currentLanguage}`]) {
    return page[`permalink_${currentLanguage}`];
  } else {
    return '';
  }
}

const i18n = (options) => {
  language = options.language
}

export {i18n as default, getLanguageDependendContent, getLanguageDependendPermalink};