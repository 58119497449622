import React, { useReducer } from 'react';
import logger from 'use-reducer-logger';

import Routes from './routes';
import StoreContext, { initialState, reducer } from './storeContext';

import './custom.scss';

const App = () => {
  const [state, dispatch] = useReducer(process.env.NODE_ENV === 'development' ? logger(reducer) : reducer, initialState);
  
  return <StoreContext.Provider value={{state, dispatch}}>
    <Routes />
  </StoreContext.Provider>
}

export default App;
