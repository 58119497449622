import React, {useContext, useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';

import Image from '../../components/Image/Image';
import i18n, {getLanguageDependendContent, getLanguageDependendPermalink} from '../../helpers/i18n';
import StoreContext from '../../storeContext';

const Branchmatrix = ({category}) => {
  const [branches, setBranches] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const {state} = useContext(StoreContext);

  i18n({language: state.language});

  useEffect(() => {
    const foundBranches = state.pages.filter(page => page.category && page.category.name && page.category.name === category);

    setBranches(foundBranches);

    return () => setRedirect(null);
  }, [category, state.pages]);

  const getMatrix = () => branches ? branches.map((page) => {
    const teaserImage = state.uploads.find(upload => page.teaser_image === upload.url);

    return <div key={page.id} className="col-12 col-md-6 col-lg-3">
      <Link className="mt-4 p text-decoration-none text-primary d-block" style={{height: 70}} to={getLanguageDependendPermalink(page)}>{getLanguageDependendContent(page, 'title')}</Link>
      <Image src={teaserImage.url} alt={teaserImage.name} fluid width="255" height="183" />
    </div>
  }) : null;

  return redirect ? 
    <Redirect to={redirect} /> :
    <div className="container-fluid mt-3 pt-4 pb-5">
      <div className="container">
        <div className="row mt-4">
          <div className="col-12 col-lg-6">
            <div className="text-primary fw-bold h6 mt-4">{state.language !== 'de' ? 'Solutions' : 'Lösungen'}</div>
            <p>{state.language !== 'de' ? 'For your branch' : 'Für Ihre Branche'}</p>
          </div>
          {getMatrix()}
        </div>
      </div>
    </div>;

}

export default Branchmatrix;