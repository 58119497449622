import React, {useContext} from 'react';
import Markdown from 'markdown-to-jsx';

import StoreContext from '../../storeContext';

const Translation = ({children, language, replace, markdown = false}) => {
  const {state} = useContext(StoreContext);
  const selectedLanguage = language ? language : state.language;
  const findTranslation = state.translations ? state.translations.find(translation => translation.key === children) : null;
  const translated = findTranslation ? findTranslation[`value_${selectedLanguage}`] : children;

  let translatedText = translated;

  if (replace) {
    for (const [key, value] of Object.entries(replace)) {
      translatedText = translatedText.replace(new RegExp(`{${key}}`,'g'), value);
    }
  }

  if (markdown) {
    return <Markdown>{translatedText}</Markdown>;
  }

  return <>{translatedText}</>;
};

export default Translation;