import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import StoreContext from '../../storeContext';
import Translation from '../Translation/Translation';

const Kontakt = ({selected}) => {
  const [company, setCompany] = useState('');
  const [democodesProductName, setDemocodesProductName] = useState('');
  const [democodesProductVariant, setDemocodesProductVariant] = useState('');
  const [email, setEmail] = useState('');
  const [forename, setForename] = useState('');
  const [formSend, setFormSend] = useState(false);
  const [formSendError, setFormSendError] = useState(false);
  const [issue, setIssue] = useState(selected ? selected : new URLSearchParams(document.location.search.substring(1)).get('issue'));
  const [lastname, setLastname] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const {state} = useContext(StoreContext);
  const [webinarDate, setWebinarDate] = useState('');
  const [webinarSubject, setWebinarSubject] = useState('');
  const [webinarTime, setWebinarTime] = useState('');

  useEffect(() => {
    return () => setRedirect(null);
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('company', company);
      formData.append('democodesProductName', democodesProductName);
      formData.append('democodesProductVariant', democodesProductVariant);
      formData.append('email', email);
      formData.append('forename', forename);
      formData.append('issue', issue);
      formData.append('lastname', lastname);
      formData.append('message', message);
      formData.append('phone', phone);
      formData.append('webinarDate', webinarDate);
      formData.append('webinarSubject', webinarSubject);
      formData.append('webinarTime', webinarTime);

      const request = await fetch('/php/sendmail.php', {
        method: 'POST',
        body: formData
      });

      const response = await request.json();

      if (response.success) {
        setFormSend(true);
      } else {
        setFormSendError(true);
      }
    } catch(e) {
      setFormSendError(true);
    }
  }

  const getTextarea = () => issue ? <div className="row mt-4">
    <div className="col">
      <label className="text-dark"><Translation markdown={true}>MESSAGE</Translation></label>
      <textarea className="form-control" name="message" rows="5" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
    </div>
  </div> : null;

  const getIssueButton = (id, title, icon) => {
    const classNames = issue === id ? 
      'bg-primary bg-gradient p-3 text-white text-center rounded link border' : 
      'bg-light bg-gradient p-3 text-primary text-center rounded link border';

    return <div className={classNames} onClick={() => setIssue(id)}>
      <i className={"fas fa-" + icon + " fa-2x d-block"}></i>
      <span className="d-block mt-2">{title}</span>
    </div>
  }

  const getPrivacyPolicyText = () => issue ? <div className="row mt-5">
    <div className="col-2 col-lg-1">
      <input className="mt-1" style={{width: 30, height: 30}} type="checkbox" name="privacyPolicy" value={privacyPolicy} onChange={(e) => setPrivacyPolicy(e.target.checked)} required />
    </div>
    <div className="col-10 col-lg-9">
      <p><Translation markdown={true}>PRIVACY_POLICY_CHECKBOX_LABEL</Translation></p>
    </div>
  </div> : null;

  const getSubmitButton = () => issue ? <div className="row mt-5">
    <div className="col-12 text-center text-lg-end">        
      <button className="btn btn-primary" type="submit"><Translation>SEND_FORM</Translation></button>
    </div>
  </div> : null;

  const getIssueGeneralForm = () => issue === 'general' ? <React.Fragment>
    {getTextarea()}
  </React.Fragment> : null;

  const getIssueWebinarForm = () => issue === 'webinar' ? <React.Fragment>
    <div className="row mt-4">
      <div className="col-12">
        <p className="text-primary"><Translation>WHEN_TO_REACH</Translation></p>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-lg-6">
        <label className="text-dark"><Translation>DATE</Translation></label>
        <input 
          className="form-control" 
          type="date" 
          name="webinarDate" 
          value={webinarDate} 
          onChange={(e) => setWebinarDate(e.target.value)} 
          min={new Date().toISOString().split('T').shift()}
          max={new Date(new Date().setMonth(new Date().getMonth()+1)).toISOString().split('T').shift()}
          required 
        />
      </div>
      <div className="col-12 col-lg-6 mt-3 mt-lg-0">
        <label className="text-dark"><Translation>TIME</Translation></label>
        <input 
          className="form-control" 
          type="time" 
          name="webinarTime" 
          value={webinarTime} 
          onChange={(e) => setWebinarTime(e.target.value)}
          min="09:00"
          max="18:00"
          required 
        />
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-12">
        <p className="text-primary"><Translation>WEBINAR_PREPARATION_SUBJECT</Translation></p>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <input className="form-control" type="text" name="webinarSubject" value={webinarSubject} onChange={(e) => setWebinarSubject(e.target.value)} required />
      </div>
    </div>
    {getTextarea()}
  </React.Fragment> : null;

  const getIssueDemocodesForm = () => issue === 'democodes' ? <React.Fragment>
    <div className="row mt-4">
      <div className="col-12 col-lg-6">
        <label className="text-dark"><Translation>PRODUCT_NAME</Translation></label>
        <input className="form-control" type="text" name="democodesProductName" value={democodesProductName} onChange={(e) => setDemocodesProductName(e.target.value)} placeholder={state.translations.find(translation => translation.key === 'PLACEHOLDER_DEMOCODES_PRODUCT_NAME')[`value_${state.language}`]} required />
      </div>
      <div className="col-12 col-lg-6 mt-3 mt-lg-0">
        <label className="text-dark"><Translation>PRODUCT_VARIANT</Translation></label>
        <input className="form-control" type="text" name="democodesProductVariant" value={democodesProductVariant} onChange={(e) => setDemocodesProductVariant(e.target.value)} placeholder={state.translations.find(translation => translation.key === 'PLACEHOLDER_DEMOCODES_PRODUCT_VARIANT')[`value_${state.language}`]} required />
      </div>
    </div>
    {getTextarea()}
  </React.Fragment> : null;

  const formError = () => formSendError ? <React.Fragment>
  <i className="mt-2 fas fa-bug fa-7x text-danger text-center d-block"></i>
  <h3 className="mt-3 fw-bold text-danger text-center"><Translation>ERROR_SENT_SUBJECT</Translation></h3>
  <p className="mt-5">
    <Translation markdown={true}>ERROR_SENT_TEXT</Translation>
  </p>

  <button className="btn btn-link" onClick={() => setRedirect('/')}><Translation>BACK_TO_STARTPAGE</Translation></button>
  </React.Fragment> : null;

  const formSendWebinar = () => formSend && issue === 'webinar' ? <React.Fragment>
    <i className="mt-2 fas fa-desktop fa-7x text-success text-center d-block"></i>
    <h3 className="mt-3 fw-bold text-success text-center"><Translation>WEBINAR_SENT_SUBJECT</Translation></h3>
    <p className="mt-5"><Translation markdown={true} replace={{
        company, 
        democodesProductName,
        democodesProductVariant,
        email, 
        forename, 
        lastname,
        message,
        phone,
      }}>WEBINAR_SENT_TEXT</Translation></p>

    <button className="btn btn-link" onClick={() => setRedirect('/')}><Translation>BACK_TO_STARTPAGE</Translation></button>
  </React.Fragment> : null;

  const formSendGeneral = () => formSend && issue === 'general' ? <React.Fragment>
    <i className="mt-2 fas fa-pen fa-7x text-success text-center d-block"></i>
    <h3 className="mt-3 fw-bold text-success text-center"><Translation>GENERAL_SENT_SUBJECT</Translation></h3>
    <p className="mt-5"><Translation markdown={true} replace={{
        company, 
        democodesProductName,
        democodesProductVariant,
        email, 
        forename, 
        lastname,
        message,
        phone,
      }}>GENERAL_SENT_TEXT</Translation></p>

    <button className="btn btn-link" onClick={() => setRedirect('/')}><Translation>BACK_TO_STARTPAGE</Translation></button>
  </React.Fragment> : null;

  const formSendDemoCodes = () => formSend && issue === 'democodes' ? <React.Fragment>
    <i className="mt-2 fas fa-fingerprint fa-7x text-success text-center d-block"></i>
    <h3 className="mt-3 fw-bold text-success text-center"><Translation>DEMOCODES_SENT_SUBJECT</Translation></h3>
    <p className="mt-5">
      <Translation markdown={true} replace={{
        company, 
        democodesProductName,
        democodesProductVariant,
        email, 
        forename, 
        lastname,
        message,
        phone,
      }}>DEMOCODES_SENT_TEXT</Translation>
    </p>

    <button className="btn btn-link" onClick={() => setRedirect('/')}><Translation>BACK_TO_STARTPAGE</Translation></button>
  </React.Fragment> : null;

  const form = () => !formSend && !formSendError ? <React.Fragment>
    <div className="row mt-2">
      <div className="col-12 col-lg-6">
        <label className="text-dark"><Translation>FORENAME</Translation></label>
        <input className="form-control" type="text" name="forename" value={forename} onChange={(e) => setForename(e.target.value)} required />
      </div>
      <div className="col-12 col-lg-6 mt-3 mt-lg-0">
        <label className="text-dark"><Translation>LASTNAME</Translation></label>
        <input className="form-control" type="text" name="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} required />
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-12">
        <label className="text-dark"><Translation>COMPANY</Translation></label>
        <input className="form-control" type="text" name="company" value={company} onChange={(e) => setCompany(e.target.value)} />
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-12 col-lg-6">
        <label className="text-dark"><Translation>E_MAIL</Translation></label>
        <input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </div>
      <div className="col-12 col-lg-6 mt-3 mt-lg-0">
        <label className="text-dark"><Translation>PHONE</Translation></label>
        <input className="form-control" type="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
      </div>
    </div>
    <div className="row mt-4">
      <div className="col">
        <p className="text-primary"><Translation>WHICH_CONCERN</Translation></p>
      </div>
    </div>
    <div className="row mt-2">
      <div className="col-12 col-lg-4 mt-3 mt-lg-0">
        {getIssueButton('general', <Translation>GENERAL</Translation>, 'pen')}
      </div>
      <div className="col-12 col-lg-4 mt-3 mt-lg-0">
        {getIssueButton('webinar', <Translation>WEBINAR</Translation>, 'desktop')}
      </div>
      <div className="col-12 col-lg-4 mt-3 mt-lg-0">
        {getIssueButton('democodes', <Translation>DEMOCODES</Translation>, 'fingerprint')}
      </div>
    </div>

    {getIssueGeneralForm()}
    {getIssueWebinarForm()}
    {getIssueDemocodesForm()}

    {getPrivacyPolicyText()}

    {getSubmitButton()}
  </React.Fragment> : null;

  return redirect ? 
    <Redirect to={redirect} /> : <React.Fragment> 
    <form className="container" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-lg-6 pe-4 border-lg-right">
          {form()}
          {formError()}
          {formSendDemoCodes()}
          {formSendGeneral()}
          {formSendWebinar()}
        </div>
        <div className="col-12 col-lg-6 mt-4 mt-lg-0 ps-lg-4 border-top border-lg-top-0">
          <h6 className="fw-bold mt-2"><Translation>BRAINORITY_COMPANYNAME</Translation></h6>
          <p className="mt-3">
            <Translation markdown={true}>BRAINORITY_COMPANY_STREET</Translation><br/>
            <Translation markdown={true}>BRAINORITY_COMPANY_POSTALCODE_CITY</Translation><br/>
          </p>
          <p>
            <Translation>PHONE</Translation>: <Translation markdown={true}>PHONE_NUMBER</Translation>
          </p>
          <p>
            <Translation>E_MAIL</Translation>: <Translation markdown={true}>E_MAIL_ADDRESS</Translation>
          </p>
        </div>
      </div>
    </form>
  </React.Fragment>
}

export default Kontakt;