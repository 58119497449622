const generatePermalink = (string) => {
  let replacedString = string;

  replacedString = replacedString.toLowerCase();
  replacedString = replacedString.replace(/ä/g, 'ae');
  replacedString = replacedString.replace(/ö/g, 'oe');
  replacedString = replacedString.replace(/ü/g, 'ue');
  replacedString = replacedString.replace(/ß/g, 'ss');
  replacedString = replacedString.replace(/\(/g, '');
  replacedString = replacedString.replace(/\)/g, '');
  replacedString = replacedString.replace(/"/g, '');
  replacedString = replacedString.replace(/[^a-zA-Z0-9]+/g, '-');
  replacedString = replacedString.slice(-1) === '-' ? replacedString.replace(/.$/, '') : replacedString;

  return replacedString;
}

export default generatePermalink;