import React from 'react';

const initialState = {
  blogCategories: [],
  blogPage: {},
  categories: [],
  language: 'de',
  languages: ['de', 'en'],
  navigationFooter: [],
  navigationMain: [],
  pages: [],
  routes: [],
  startpage: {},
  uploads: [],
};

function reducer(store, action) {
  switch (action.type) {
    case 'UPDATE_STORE':
      return {...store, ...action.payload};
    case 'CHANGE_LANGUAGE':
      return {...store, language: action.payload};
    default:
      throw new Error();
  }
}

const StoreContext = React.createContext();

export { StoreContext as default, initialState, reducer };