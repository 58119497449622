import React, {Component} from 'react';

import getPermalinkFromUploads from '../../helpers/getPermalinkFromUploads';

class MediaPdf extends Component {

  state = {
    pdfSrc: '',
    thumbnail: ''
  }

  componentDidMount = () => {
    if (!this.props.uploads) return false;

    this.setState({
      pdfSrc: getPermalinkFromUploads(this.props, 'src'),
      thumbnail: this.props.thumbnail ? getPermalinkFromUploads(this.props, 'thumbnail') : ''
    })
  }

  render = () => {
    return this.props.thumbnail ? 
      <a href={this.state.pdfSrc}><img src={this.state.thumbnail} alt={this.props.alt} className={this.props.className} /></a> : 
      <a href={this.state.pdfSrc}><span className="fas fa-file-pdf fa-4x"></span></a>
  }

}

export default MediaPdf;