import React, {useContext, useEffect, useState} from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import {Button, ButtonGroup, Dropdown} from 'react-bootstrap';

import DropdownComponent from '../Dropdown/Dropdown';
import i18n, {getLanguageDependendContent, getLanguageDependendPermalink} from '../../helpers/i18n';
import Megamenu from '../Megamenu/Megamenu';
import SearchBar from '../Search/SearchBar';
import StoreContext from '../../storeContext';
import Translation from '../Translation/Translation';

const Header = ({page}) => {
  const [activeElement, setActiveElement] = useState(null);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const {state, dispatch} = useContext(StoreContext);
  const [redirect, setRedirect] = useState(null);
  let history = useHistory();

  i18n({language: state.language});

  useEffect(() => {
    showBurgerMenu || showSearchBar ? 
      document.querySelector('body').classList.add('overflow-hidden') : 
      document.querySelector('body').classList.remove('overflow-hidden');

    return () => setRedirect(null);
  });

  const toggleActiveElement = (elementId) => 
    (activeElement === elementId) ? 
      setActiveElement(null) : 
      setActiveElement(elementId);

  const goToHome = () => {
    const startpage = state.pages.find(page => page.startpage);
    
    setRedirect(getLanguageDependendPermalink(startpage));
  }

  const changeLanguage = (language) => {
    dispatch({type: 'CHANGE_LANGUAGE', payload: language});

    history.push(language === 'de' ? page.permalink : page[`permalink_${language}`]);
  }

  const getNavigationElements = (isMobile) => state.navigationModel.map(navigation => {

    if (navigation.type === 'singlePage') {
      return (
        <Link key={navigation.element.id} to={getLanguageDependendPermalink(navigation.element.pages[0])} className="d-block d-lg-inline mb-3 mb-lg-0 me-3 link text-decoration-none">
          {getLanguageDependendContent(navigation.element, 'name')}
        </Link>
      )
    }

    if (navigation.type === 'dropdown') {
      return isMobile ? (
        <span key={navigation.element.id} className="d-block mb-3 mb-lg-0 me-3 link dropdown-heading-item" onClick={() => toggleActiveElement(navigation.element.id)}>
          {getLanguageDependendContent(navigation.element, 'name')}
          {activeElement === navigation.element.id ? <DropdownComponent isMobile={isMobile} navigation={navigation.element} /> : null}
        </span>
      ) : (
        <span key={navigation.element.id} className="mb-3 mb-lg-0 me-3 link dropdown-heading-item">
          {getLanguageDependendContent(navigation.element, 'name')}
          <DropdownComponent isMobile={isMobile} navigation={navigation.element} />
        </span>
      )
    }
    
    if (navigation.type === 'megamenu') {
      return (
        <span key={navigation.element.id} className="d-block d-lg-inline mb-3 mb-lg-0 me-3 link megamenu-heading-item">
          {getLanguageDependendContent(navigation.element, 'name')}
          <Megamenu isMobile={isMobile} categories={navigation.element.categories} />
        </span>
      )
    }

    if (navigation.type === '404Page') {
      return (
        <Link key={navigation.element.id} to="/ups-404" className="d-block d-lg-inline me-3 text-decoration-none">
          {getLanguageDependendContent(navigation.element, 'name')}
        </Link>
      )
    }

    return false;

  });

  const desktopNavigation = () => (<React.Fragment>
    <header id="menue" className="py-1 sticky-top bg-white">
      <nav className="container">
        <div className="row align-items-center">
          <div className="col-3 logo">
            <img src="/assets/logos/brainority-software-gmbh-logo.svg" width="200" height="30" alt="Brainority Software GmbH" onClick={goToHome} />
          </div>
          <div className="col-9 text-end">
            <div className="row mt-2 align-items-center">
              <div className="col-9 col-lg-12">
                <Button variant="link" size="sm" className="text-decoration-none" aria-label="search" onClick={() => setShowSearchBar(true)}>
                  <i className="fas fa-search me-1" /> <span className="d-none d-lg-inline"><Translation>SEARCH</Translation></span>
                </Button>
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle variant="link" id="dropdown-basic" size="sm" aria-label="change language" className="text-decoration-none">
                    <i className="fas fa-globe" /> <span className="d-none d-lg-inline"><Translation>LANGUAGE</Translation></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => changeLanguage('de')}>Deutsch</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-3 d-lg-none">
                <i className="fas fa-bars fa-3x" onClick={() => setShowBurgerMenu(true)} />
              </div>
            </div>
            <div className="row mt-4 d-none d-lg-block">
              <div className="col">
                {getNavigationElements(false)}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </React.Fragment>
  );

  const mobileNavigation = () => showBurgerMenu ? (<React.Fragment>
    <div className="container-fluid bg-white position-fixed w-100 h-100 overflow-auto d-block d-lg-none" style={{zIndex:100}}>
      <div className="container">
        <div className="row mt-3 mb-3 align-items-center">
          <div className="col-9 text-start">
            <span className="h6 text-primary d-block mt-4 fw-bold">Navigation</span>
          </div>
          <div className="col-3 text-end" onClick={() => setShowBurgerMenu(false)}>
            <i className="fas fa-times fa-3x text-primary mt-3" />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            {getNavigationElements(true)}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  ) : null;

  return redirect ? 
    <Redirect to={redirect} /> : 
    <React.Fragment>
      <SearchBar show={showSearchBar} onHide={() => setShowSearchBar(false)} />
      {mobileNavigation()}
      {desktopNavigation()}
    </React.Fragment>;

}

export default Header;