import React, {useContext, useEffect, useState} from 'react';
import StoreContext from '../../storeContext';

import 'bootstrap/js/src/modal.js';

const Video = ({alt, className, preview, src, time, modalSize = 'xl'}) => {
  const [showVideo, setShowVideo] = useState(false);
  const {state} = useContext(StoreContext);

  useEffect(() => {
    const videoModalElement = document.getElementById('videoModal');

    videoModalElement.addEventListener('hidden.bs.modal', (event) => {
      setShowVideo(false);
    })
  });

  return <>

    <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-${modalSize}`}>
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowVideo(false)}></button>
          </div>
          <div className="modal-body">
            {showVideo ? 
              <video width="100%" controls autoPlay>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
              </video> : 
            null}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setShowVideo(false)}>
              {state.language !== 'de' ? 'Close window' : 'Fenster schließen'}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className={className + ' container'} data-bs-toggle="modal" data-bs-target="#videoModal" onClick={() => setShowVideo(true)}>
      <div className="row align-items-end">
        <img src={preview} alt={alt} className="img-fluid shadow p-0" />
        <div className="col-8 col-md-10 col-lg-9 col-xl-10 mt-n5 p-0">
          <div className="video-play-button" />
        </div>
        <div className="col-4 col-md-2 col-lg-3 col-xl-2 mt-n5 pb-2">
          {time ? <div className="bg-primary border text-center rounded text-white">{time}</div> : null}
        </div>
      </div>
    </div>

  </>
}

export default Video;