import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {getLanguageDependendContent} from '../../helpers/i18n';
import StoreContext from '../../storeContext';
import Translation from '../Translation/Translation';

const SearchBar = ({show, onHide}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState(false);
  const {state} = useContext(StoreContext);

  useEffect(() => {

    show ? 
      document.querySelector('body').classList.add('overflow-hidden') : 
      document.querySelector('body').classList.remove('overflow-hidden');

    const search = () => {
      if (searchQuery === '') return false;
  
      const searchChunks = searchQuery.split(/([ .,]+)/g).filter(Boolean).filter(q => q.trim());
  
      const foundPages = state.pages.filter(page => {
        const matchedPageDescriptions = [];
        const matchedPageTitles = [];

        const pageDescription = (page.description && state.language === 'de') ? page.description : page[`description_${state.language}`];
        const pageTitle = (page.title && state.language === 'de') ? page.title : page[`title_${state.language}`];
  
        searchChunks.forEach(chunk => pageDescription && pageDescription.toLowerCase().includes(chunk.toLowerCase()) ? matchedPageDescriptions.push(page) : false);
        searchChunks.forEach(chunk => pageTitle && pageTitle.toLowerCase().includes(chunk.toLowerCase()) ? matchedPageTitles.push(page) : false);
  
        if (matchedPageTitles.length === searchChunks.length) {
          return true;
        }

        if (matchedPageDescriptions.length === searchChunks.length) {
          return true;
        }
  
        return false;
      });

  
      setSearchResult(foundPages);
    }

    search();
  }, [searchQuery, show, state.language, state.pages]);

  const getFormattedDescription = (description) => {
    if (!description) return false;

    let replacedString = description;

    replacedString = replacedString.replace(/#/g, '');
    replacedString = replacedString.replace(/\*/g, '');
    replacedString = replacedString.replace(/_/g, '');
    replacedString = replacedString.replace(/\[/g, '');
    replacedString = replacedString.replace(/\]/g, '');
    replacedString = replacedString.replace(/<[^>]*>?/gm, '');

    if (replacedString.length > 400) {
      const wordbreakPosition = replacedString.substring(400, replacedString.length).indexOf(' ');
      replacedString = replacedString.substring(0, 400+wordbreakPosition)
    }

    return replacedString;
  }

  return show ? <React.Fragment>
    <div id="searchbar" className="container-fluid border position-fixed overflow-auto bg-white h-100 w-100">
      <div className="container">
        <div className="row">
          <div className="col text-end">
            <i className="fas fa-times fa-3x text-primary mt-3 cursor-pointer" onClick={onHide} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 text-center">
            <h1 className="mt-0 mt-lg-5 text-primary fw-bold"><i className="fa fa-search me-3 text-primary" /><Translation>SEARCH</Translation></h1>
            <input className="form-control form-control-lg mt-3" type="text" placeholder="" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} autoFocus/>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            {searchResult && searchQuery !== '' ? <div className="fw-bold mb-3 h4">{searchResult.length} <Translation>RESULTS_FOUND</Translation></div> : null}
            {searchResult && searchQuery !== '' ? searchResult.map(page => 
              <div key={page.id} className="border-bottom py-4">
                <Link to={page.permalink} className="text-primary m-0 text-decoration-none">{getLanguageDependendContent(page, 'title')}</Link>
                <p className="display-4 m-0">{getFormattedDescription(getLanguageDependendContent(page, 'description'))} ...</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment> : null;
}

export default SearchBar;