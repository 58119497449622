import React from 'react';
import {Image} from 'react-bootstrap';

const ImageWrapper = (props) => {
  
  const imagePath = props.src.split('.').shift();
  const srcSet = `${imagePath}@150.webp 150w, ${imagePath}@300.webp 300w, ${imagePath}@500.webp 500w, ${imagePath}@800.webp 800w, ${imagePath}.webp 1200w, ${props.src}`;

  return <picture>
    <source type="image/webp" srcSet={srcSet} />
    <source type="image/jpeg" srcSet={props.src} />
    <Image src={props.src} {...props} />
  </picture>
}

export default ImageWrapper;