import React, {useContext} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import StoreContext from '../../storeContext';

const PageUploads = () => {
  const imageExtensionList = ['.png', '.jpg', '.jpeg', '.gif', '.svg']
  const {state} = useContext(StoreContext);

  const copy = (id) => {
    const element = document.getElementById(id);

    navigator.clipboard.writeText(element.innerHTML)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      kopieren
    </Tooltip>
  );

  const getUploadsList = () => state.uploads.map((upload, index) => 
    <div className="row bg-light border my-2 py-3" style={{minHeight:100}} key={index}>
      <div className="col-2">
        {imageExtensionList.includes(upload.ext) ? 
          <img src={upload.url} className="img-fluid" alt={upload.name} style={{maxHeight:90}} /> :
          <>{upload.mime}</>
        }
      </div>
      <div className="col-10">
        <span className="display-4 d-block fw-bold mb-2">{upload.name}</span>
        <a className="display-4" id={upload.id} href={upload.url}>{upload.url}</a> 
        <OverlayTrigger 
          placement="right" 
          delay={{ show: 250, hide: 400 }} 
          overlay={renderTooltip}
        >
          <i className="far fa-copy cursor-pointer ms-2" onClick={() => copy(upload.id)}/>
        </OverlayTrigger>

        {upload.usedByPages.length > 0 ? <>
          <span className="mt-4 display-4 d-block fw-bold">Verwendet in Seiten</span>
          <ul>
            {upload.usedByPages.map(page => <li key={page.id}><a className="display-4" href={page.permalink}>{page.title}</a></li>)}
          </ul>
        </> : null}
      </div>
    </div>
  );

  return <>
    <Header />
    <div className="container mt-3">
      {getUploadsList()}
    </div>
    <Footer />
  </>

}

export default PageUploads;