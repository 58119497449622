import React, {lazy} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import Branchmatrix from '../Branchmatrix/Branchmatrix';
import Button from '../Button/Button';
import Image from '../Image/Image';
import Kontakt from '../Kontakt/Kontakt';
import LinkChecker from '../LinkChecker/LinkChecker';
import MediaPdf from '../Media/MediaPdf';
import Milestone from '../Milestone/Milestone';
import News from '../News/News';
import Table from '../Table/Table';
import TableBody from '../Table/TableBody';
import TableHead from '../Table/TableHead';
import TableItem from '../Table/TableItem';
import TableRow from '../Table/TableRow';
import Textbox from '../Textbox/Textbox';
import Video from '../Video/Video';

const QRCode = lazy(() => import('../QRCode/QRCode'));

const options = (routes, uploads) => {
  return {
    overrides: {
      h1: {
        props: {
          className: 'text-primary mt-4',
        },
      },
      h2: {
        props: {
          className: 'text-secondary mt-4',
        },
      },
      h3: {
        props: {
          className: 'text-primary mt-4',
        },
      },
      h4: {
        props: {
          className: 'text-secondary mt-4',
        },
      },
      h5: {
        props: {
          className: 'text-primary mt-4',
        },
      },
      h6: {
        props: {
          className: 'text-secondary mt-4',
        },
      },
      a: {
        component: LinkChecker,
        props: {
          routes,
          uploads: uploads.map(upload => `/uploads/${upload.permalink}`)
        }
      },
      p: {
        props: {
          className: 'text-justify mt-4'
        }
      },
      ul: {
        props: {
          className: 'list-style-primary'
        }
      },
      li: {
        props: {
          className: 'my-lg-3'
        }
      },
      pre: {
        props: {
          className: 'border p-4 bg-light my-4'
        }
      },
      img: {component: Image},
      Branchmatrix: {component: Branchmatrix},
      Button: {component: Button},
      Col: {component: Col},
      Container: {component: Container},
      Divider: {component: () => <></>},
      Image: {component: Image},
      Kontakt: {component: Kontakt},
      MediaPdf: {component: MediaPdf, props: { uploads }},
      Milestone: {component: Milestone},
      News: {component: News},
      QRCode: {component: QRCode},
      Row: {component: Row},
      TableComponent: {component: Table},
      TableBody: {component: TableBody},
      TableHead: {component: TableHead},
      TableRow: {component: TableRow},
      TableItem: {component: TableItem},
      Textbox: {component: Textbox},
      Video: {component: Video},
    }
  }
}

export default options;