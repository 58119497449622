import React from 'react';

const Button = (props) => {

  return props.href
  ? <a href={props.href} title={props.children} {...props}>{props.children}</a>
  : <button {...props}>{props.children}</button>

}

export default Button;