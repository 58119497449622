import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import i18n, {getLanguageDependendContent, getLanguageDependendPermalink} from '../../helpers/i18n';
import StoreContext from '../../storeContext';

const Dropdown = ({isMobile, navigation}) => {
  const {state} = useContext(StoreContext);

  i18n({language: state.language});

  const mobileNavigation = () => isMobile ? (
    <ul className="mt-2 ms-4">
      {navigation.pages.map(page => (
        <li key={page.id}>
          <Link to={getLanguageDependendPermalink(page)} className="text-decoration-none">{getLanguageDependendContent(page, 'title')}</Link>
        </li>
      ))}
    </ul>
  ) : null;

  const desktopNavigation = () => !isMobile ? (
    <span className="dropdown-content-items mt-n1 pt-1 unlink">
      <span className="d-block bg-light text-start shadow rounded px-4 py-4">
        <span className="row">
          <ul>
            {navigation.pages.map(page => (
            <li key={page.id}>
              <Link to={getLanguageDependendPermalink(page)} className="text-decoration-none">{getLanguageDependendContent(page, 'title')}</Link>
            </li>
            ))}
          </ul>
        </span>
      </span>
    </span>
  ) : null;

  return <React.Fragment>
    {mobileNavigation()}
    {desktopNavigation()}
  </React.Fragment>

}

export default Dropdown;