import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';

import i18n, {getLanguageDependendContent, getLanguageDependendPermalink} from '../../helpers/i18n';
import StoreContext from '../../storeContext';

const Megamenu = ({categories, isMobile}) => {
  const {state} = useContext(StoreContext);
  const [openCategoryNavigation, setOpenCategoryNavigation] = useState(null);

  i18n({language: state.language});

  const toggleOpenCategoryNavigation = (activeCategoryId) => (activeCategoryId === openCategoryNavigation) ? 
    setOpenCategoryNavigation(false) : 
    setOpenCategoryNavigation(activeCategoryId);

  const getCategoriesAndRelatedPages = () => {
    if (!categories) return false;

    const jsxCategories = categories.map(category => (
      <div key={category.id} className="col">
        <div className="text-secondary mb-4 h6">{getLanguageDependendContent(category, 'name')}</div>
        <ul className="list-unstyled">
          {category.pages.map(page => 
            <li key={page.id}>
              <Link to={getLanguageDependendPermalink(page)} className="text-decoration-none">{getLanguageDependendContent(page, 'title')}</Link>
            </li>
          )}
        </ul>
      </div>));

    return <>{jsxCategories}</>;
  }

  const desktopNavigation = () => !isMobile ? <React.Fragment>
    <div className="megamenu mt-n1 pt-1 unlink">
      <div className="bg-light text-start shadow rounded p-5">
        <div className="row">
          {getCategoriesAndRelatedPages()}
        </div>
      </div>
    </div>
  </React.Fragment> : null;

  const mobileNavigation = () => {
    if (!isMobile) return false;

    const jsxCategories = categories.map(category => {
      const arrow = (openCategoryNavigation === category.id) ? <i className="fas fa-arrow-down text-secondary" /> : <i className="fas fa-arrow-right" />;
      const itemClasses = (openCategoryNavigation === category.id) ? "ms-2 border-start border-primary ps-3" : "ms-4 border-start ps-3 border";

      return <div key={category.id} className="col">
        <div className="text-primary p mt-2" onClick={() => toggleOpenCategoryNavigation(category.id)}>{arrow} {getLanguageDependendContent(category, 'name')}</div>
        {openCategoryNavigation === category.id ? <React.Fragment>
          <ul className={itemClasses}>
            {category.pages.map(page => 
            <li key={page.id}>
              <Link to={getLanguageDependendPermalink(page)} className="text-decoration-none">{getLanguageDependendContent(page, 'title')}</Link>
            </li>
            )}
          </ul>
        </React.Fragment> : null}
      </div>;

    });

    return jsxCategories;
  }

  return <React.Fragment>
    {mobileNavigation()}
    {desktopNavigation()}
  </React.Fragment>

}

export default Megamenu;