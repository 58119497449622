import React, {useEffect, useContext} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import generatePermalink from './helpers/generatePermalink';
import i18n, {getLanguageDependendPermalink} from './helpers/i18n';
import PageCorporateIdentity from './components/Page/PageCorporateIdentity';
import PageSitemap from './components/Page/PageSitemap';
import PageStandard from './components/Page/PageStandard';
import PageUploads from './components/Page/PageUploads';
import StoreContext, { initialState } from './storeContext';

const Routes = () => {
  const { state, dispatch } = useContext(StoreContext);

  i18n({language: state.language});

  const fetchObjectsFromStrapi = async () => {
    const routes = [];
    let dataModel = {...initialState};

    const databaseRequest = await fetch('/database.json');
    const databaseResponse = databaseRequest.status === 200 ? await databaseRequest.text() : undefined;
    const database = databaseResponse ? JSON.parse(databaseResponse) : undefined;

    if (database) {
      dataModel = database;

    } else {
      const blogCategoriesRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/blog-categories`);
      const categoriesRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/categories`);
      const navigationFooterRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/navigation-footers?_sort=sort:ASC`);
      const navigationMainRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/navigation-mains?_sort=sort:ASC`);
      const pagesRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/seitens`);
      const translationsRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/translations`);
      const uploadsCountRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/upload/files/count`);
      const uploadsCount = await uploadsCountRequest.json();
      const uploadsRequest = await fetch(`${process.env.REACT_APP_STRAPI_URL}/upload/files?_limit=${uploadsCount.count}`);

      dataModel = {
        blogCategories: await blogCategoriesRequest.json(),
        categories: await categoriesRequest.json(),
        navigationFooter: await navigationFooterRequest.json(),
        navigationMain: await navigationMainRequest.json(),
        pages: await pagesRequest.json(),
        translations: await translationsRequest.json(),
        uploads: await uploadsRequest.json(),
      }
    }
    
    dataModel['navigationModel'] = [];

    const startpage = dataModel.pages.find(page => page.startpage);
    const blogPage = dataModel.pages.find(page => page.is_blog_page);

    dataModel.pages.forEach(page => {
      state.languages.forEach(language => {
        const categoryHasNavigation = dataModel.categories.find(category => (page.category && category.id === page.category.id));
        const dropdownCategory = dataModel.navigationMain.find(navigation => navigation.pages.length > 1 && navigation.pages.find(navigationPage => navigationPage.id === page.id));

        const pageCategoryName = language !== 'de' && page.category ? page.category[`name_${language}`] : page.category?.name;
        const dropdownCategoryName = language !== 'de' && dropdownCategory ? dropdownCategory[`name_${language}`] : dropdownCategory?.name;
        const navigationName = language !== 'de' && categoryHasNavigation?.navigation_main ? categoryHasNavigation.navigation_main[`name_${language}`] : categoryHasNavigation?.navigation_main.name;
        const pageTitle = language !== 'de' && page[`title_${language}`] ? page[`title_${language}`] : page.title;
        
        const permalinkBlogPage = page.blog_categories.length > 0 ? `${language === 'de' ? blogPage.permalink : blogPage[`permalink_${language}`]}` : '';
        const permalinkCatagory = page.category && page.blog_categories.length === 0 ? `/${generatePermalink(pageCategoryName)}` : '';
        const permalinkDropdownCategory = dropdownCategory ? `/${generatePermalink(dropdownCategoryName)}` : '';
        const permalinkLanguagePrefix = language !== 'de' ? `/${language}` : '';
        const permalinkNavigationElement = categoryHasNavigation && categoryHasNavigation.navigation_main ? `/${generatePermalink(navigationName)}` : '';
        const permalinkPageTitle= generatePermalink(pageTitle);

        let permalink = '';

        // Permalink Struktur
        // Kann unterschiedlich sein, je nach Seiten-Typ der ermittelt wird.
        if (page.blog_categories.length) { // artikel
          permalink = `${permalinkBlogPage}/${permalinkPageTitle}`;
        } else {
          permalink = `${permalinkLanguagePrefix}${permalinkNavigationElement}${permalinkCatagory}${permalinkDropdownCategory}/${permalinkPageTitle}`;
        }

        if (language === 'de') {
          page['permalink'] = permalink;
        } else {
          page[`permalink_${language}`] = permalink;
        }

        routes.push(permalink);
      });
    });

    dataModel.navigationMain.forEach((navigationElement) => {
      const subCategories = navigationElement.categories.filter(navigationElementCategory => {
        return dataModel.categories.filter(passedCategory => {
          return navigationElementCategory.id === passedCategory.id;
        })
      });
      
      subCategories.forEach(subCategory => {
        const subCategoryPages = dataModel.pages.filter(page => (page.category && page.category.id === subCategory.id));

        subCategory['pages'] = subCategoryPages;
      });

      navigationElement.pages.forEach((navigationPage, navigationPageIndex) => {
        const foundPageInDataModel = dataModel.pages.find(dataModelPage => dataModelPage.id === navigationPage.id);

        navigationElement.pages[navigationPageIndex] = {...navigationPage, ...foundPageInDataModel};
      });
      
      if (navigationElement.pages && navigationElement.pages.length === 1) {
        dataModel.navigationModel.push({type: 'singlePage', element: navigationElement});
      }

      else if (navigationElement.pages && navigationElement.pages.length > 0) {
        dataModel.navigationModel.push({type: 'dropdown', element: navigationElement});
      }
      
      else if (navigationElement.categories && navigationElement.categories.length > 0) {
        dataModel.navigationModel.push({type: 'megamenu', element: navigationElement});
      }
      
      else {
        dataModel.navigationModel.push({type: '404Page', element: navigationElement});
      }

    });

    dataModel.uploads.forEach(upload => {
      const uploadName = upload.name.replace(upload.ext, '');
      const permalink= generatePermalink(uploadName);
      
      const findUploadInPageTeaser = dataModel.pages.filter(page => page.teaser_image === `/uploads/${permalink}${upload.ext}`);
      const findUploadInPageContent = dataModel.pages.filter(page => page.description && page.description.includes(`/uploads/${permalink}${upload.ext}`));

      upload['permalink'] = `${permalink}${upload.ext}`;
      upload['url'] = `/uploads/${permalink}${upload.ext}`;
      upload['usedByPages'] = [...findUploadInPageTeaser, ...findUploadInPageContent];
    });

    dispatch({type: 'UPDATE_STORE', payload: {
      blogCategories: dataModel['blogCategories'],
      blogPage,
      categories: dataModel['categories'],
      navigationFooter: dataModel['navigationFooter'],
      navigationMain: dataModel['navigationMain'],
      navigationModel: dataModel['navigationModel'],
      pages: dataModel['pages'],
      translations: dataModel['translations'],
      routes,
      startpage,
      uploads: dataModel['uploads'],
    }});
  }

  useEffect(() => {
    const languageFoundInUrl = window.location.pathname.split('/')[1];

    if (state.language !== languageFoundInUrl && state.languages.includes(languageFoundInUrl)) {
      dispatch({type: 'CHANGE_LANGUAGE', payload: languageFoundInUrl});
    }

    if (state.pages.length <= 0) {
      fetchObjectsFromStrapi()
    }
  });

  const getDynamicRoutes = () => (state.pages.length > 0) ?
    state.pages.map(page => 
      state.languages && state.languages.map(language => 
        <Route 
          exact
          key={page.id} 
          path={getLanguageDependendPermalink(page)} 
          component={() => 
            <PageStandard page={page} />
          } 
        />
      )
    ) : 
    false;

  return state.pages.length > 0 ? (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to={state.startpage.permalink} />  
        </Route>
        <Route path="/corporate-identity-ci" component={() => <PageCorporateIdentity pages={state.pages} uploads={state.uploads} navigationMain={state.navigationMain} />} />
        <Route path="/sitemap" component={() => <PageSitemap page={{permalink: '/sitemap'}} />} />
        <Route path="/uploads" component={() => <PageUploads />} />
        {getDynamicRoutes()}
        <Route path="*">
          <Redirect to="/ups-404" />
        </Route>
      </Switch>
    </Router>
  ) : null;
}

export default Routes;