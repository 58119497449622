import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import {getLanguageDependendContent, getLanguageDependendPermalink} from '../../helpers/i18n';
import StoreContext from '../../storeContext';

const News = ({exclude}) => {
  const {state} = useContext(StoreContext);
  const articles = state.pages.filter(page => page.blog_categories.find(blogCategory => blogCategory.name !== exclude)).reverse();

  return <React.Fragment>
    <div className="container-fluid mt-3 py-4">
      <div className="container">
        {articles.map((article, index) => 
          <div className="row border-bottom mb-5" key={index}>
            <div className="col">
              <Link to={getLanguageDependendPermalink(article)} className="text-primary h6 fw-bold text-decoration-none">{getLanguageDependendContent(article, 'title')}</Link><br />
              {article.blog_categories.map(category => 
                <span key={category.id} className="me-3">
                  <img src={category.icon} alt={getLanguageDependendContent(category, 'name')} style={{width:30}} width="30" height="30" /> {getLanguageDependendContent(category, 'name')}
                </span>
              )}
              <p className="mt-4">{getLanguageDependendContent(article, 'teaser')}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  </React.Fragment>;
}

export default News;